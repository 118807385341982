.alerta {
    float: right;
    margin: 10px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10000;
    max-width: 100%;
}

.alerta>span {
    font-size: 14px;
}

.alerta>svg {
    font-size: 20px;
    float: left;
}