#carregandoPromo {
    min-height: 80vh;
    display: flex;
}

.grade-produtos-carregando {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.grade-produtos-carregando>.card {
    width: 100%;
    max-height: 374px;
    border-radius: 0 !important;
}

.grade-produtos-carregando>.card>.card-img {
    border-radius: 0 !important;
}

@media (min-width: 576px) {
    .grade-produtos-carregando>.card {
        width: calc(100% / 2);
    }
}

@media (min-width: 768px) {
    .grade-produtos-carregando>.card {
        width: calc(100% / 3);
    }
}

@media (min-width: 992px) {
    .grade-produtos-carregando>.card {
        width: calc(100% / 4);
    }
}

@media (min-width: 1200px) {
    .grade-produtos-carregando>.card {
        width: calc(100% / 5);
    }
}

@media (min-width: 1400px) {
    .grade-produtos-carregando>.card {
        width: calc(100% / 6);
    }
}

.grade-produtos-carregando>.card {
    border: none;
    border-radius: 0;
    padding: 1rem 0.7rem;
    box-shadow: 0 0 0 1px #d2d2d2;
}

.grade-produtos-carregando>.card>.card-body {
    padding: 0;
}

.grade-produtos-carregando>.card .nome-produto {
    margin: 0.6rem 0;
    height: 60px;
}

.grade-produtos-carregando>.card .precos {
    height: 48px;
}

.grade-produtos-carregando>.card .div-quantidade {
    height: 31px;
}

@keyframes placeHolderShimmerCard {
    0% {
        background-position: -400px 0
    }

    100% {
        background-position: 400px 0
    }
}

@keyframes placeHolderShimmerBanner {
    0% {
        background-position: -1200px 0
    }

    100% {
        background-position: 1200px 0
    }
}

@keyframes placeHolderShimmerSetores {
    0% {
        background-position: -1200px 0
    }

    100% {
        background-position: 1200px 0
    }
}

.banner-loading {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerBanner;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #BBBBBB 20%, #eeeeee 33%);
    background-size: 1200px 250px;
    max-height: 300px;
    position: relative;
    height: 300px;
    width: 100%;
}

.setor-loading {
    max-width: 1102px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerSetores;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #BBBBBB 20%, #eeeeee 33%);
    background-size: 1200px 105px;
    margin-bottom: 6px;
}

.card-loading .esconder {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerCard;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #CCCCCC 20%, #eeeeee 33%);
    background-size: 400px 134px;
    position: relative;
}

.card-loading>.card-img {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerCard;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #CCCCCC 20%, #eeeeee 33%);
    background-size: 400px 134px;
    position: relative;
    height: 100%;
    height: 180px;
}

#categorias-destaque .setor-loading svg {
    display: block;
    height: 80px;
}

#categorias-destaque .setor-loading svg path {
    fill: #ffffff;
}

#categorias-destaque .descricao-carregando {
    max-width: 1102px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerSetores;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #BBBBBB 20%, #eeeeee 33%);
    background-size: 1200px 105px;
    width: 80px;
    height: 18px;
}

.container-setor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    height: 111px;
}