#navbar-cabecalho {
    padding: 0.74rem 0;
    transition: padding ease-in-out 0.2s;
}

#navbar-cabecalho.navbar-fixo {
    padding: 0.2rem 0;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);
}

button:focus,
.form-control:focus {
    box-shadow: none;
}

#link-entre-cadastre-se {
    align-items: center;
    gap: 6px;
}

#link-entre-cadastre-se svg {
    font-size: 29px;
}

#link-entre-cadastre-se>div {
    display: flex;
    flex-direction: column;
}

#link-entre-cadastre-se span {
    white-space: nowrap;
    line-height: 1.1205;
}

#link-entre-cadastre-se #entre {
    font-size: 1rem;
    font-weight: bold;
}

#link-entre-cadastre-se #cadastre-se {
    font-size: 0.8rem;
}

#link-carrinho svg {
    font-size: 30px;
}

#link-carrinho .badge {
    top: 20%;
    left: 80%;
    padding: 0.2rem 0.40rem;
}

#link-carrinho .badge span {
    font-size: 0.7rem;
    line-height: normal;
}

#link-favoritos {
    align-items: center;
    gap: 6px;
}

#link-favoritos svg {
    font-size: 29px;
}

#link-favoritos>div {
    display: flex;
    flex-direction: column;
}

#link-favoritos span {
    white-space: nowrap;
    line-height: 1.187;
    font-size: 0.85rem;
}

#barra-menu {
    margin-top: 71.91px;
    background-color: #0d6efd;
    color: white;
    padding-top: 0.11em;
    padding-bottom: 0.11em;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.15);
}

#barra-menu .container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
}

#modo-local-entrega {
    align-items: center;
    gap: 6px;
}

#modo-local-entrega svg {
    line-height: normal;
    font-size: 28px;
}

#modo-local-entrega>div {
    display: flex;
    flex-direction: column;
}

#modo-local-entrega span {
    white-space: nowrap;
    line-height: normal;
}

#modo-local-entrega #modo-entrega {
    color: #dadada;
    font-size: 0.65rem;
    line-height: 1.1;
}

#modo-local-entrega #local-entrega {
    font-size: 0.85rem;
    font-weight: bold;
    line-height: 1.1;
}


#barra-menu .container>a,
#barra-menu .container>li>a {
    color: white;
    font-size: 0.95rem;
}

#menu-categorias,
#link-lista-categorias {
    position: relative;
}

#link-lista-categorias {
    cursor: pointer;
    z-index: 3;
    transition: transform .2s ease-in-out,
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out;
}

#link-lista-categorias>svg {
    font-size: 14px;
    transition: transform .2s ease-in-out;
}

#barra-menu #link-lista-categorias.mostrar {
    background-color: white;
    color: #0d6efd;
}

#link-lista-categorias.mostrar>svg {
    transform: rotate(180deg);
}

#menu-categorias ul {
    list-style: none;
}

#lista-categorias {
    z-index: 2;
    padding: 0 0 0.5rem;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 100%;
    width: 100%;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    transition: opacity .15s ease-in-out, transform .15s ease-in-out;
    opacity: 0;
    transform: translateY(-50px);
}

#lista-categorias.mostrar {
    opacity: 1;
    transform: translateY(0px);
}

.link-categoria {
    font-size: 0.88rem;
    color: #303030;
    text-decoration: none;
    cursor: pointer;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#lista-categorias li:last-child .link-categoria {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}


.link-categoria:hover,
.link-categoria.mostrar {
    background-color: #F5F5F5;
}

.link-categoria>svg {
    font-size: 11px;
}

.lista-grupos {
    z-index: 1;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #F5F5F5;
    border-radius: 0.375rem;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.10);
    padding: 0.3rem 0;
    min-width: 180px;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    opacity: 0;
    transform: translateX(-30px);
}

.lista-grupos.mostrar {
    opacity: 1;
    transform: translateX(0px);
}

.lista-grupos>li>a {
    display: block;
    padding: 0.28rem 0.8rem;
    font-size: 0.88rem;
    white-space: nowrap;
    cursor: pointer;
    color: #101010;
    text-decoration: none;
}

.lista-grupos>li>a:hover {
    color: #0d6efd;
}

.dropdown-toggle::after {
    display: none;
}

.botao-limpo {
    background: transparent;
    border: none;
}

#div-modo-local-entrega.carregando span {
    height: 5px;
    width: 200px;
}

body,
nav {
    padding-right: 0 !important;
}

.botoes-modo-entrega {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    border: #0d6efd 1px solid;
    justify-content: center;
    color: #0d6efd;
    transition: all 0.2s ease-in-out;
    font-size: 13px;
    line-height: 1;
}

.botoes-modo-entrega:hover {
    color: white;
    background-color: #0d6efd;
}

#lista-lojas {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#lista-lojas .form-check {
    width: 100%;
    background-color: #efefef;
    padding-left: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}

#lista-lojas .form-check small {
    font-size: 12px;
}

#lista-lojas .endereco {
    display: flex;
    flex-direction: column;
}

#lista-lojas .endereco>.rua {
    font-size: 11px;
    line-height: 1;
}

#div-modo-local-entrega {
    display: flex;
    flex-direction: column;
}

#div-modo-local-entrega.carregando {
    height: 60%;
}