.container-login {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-login .logo>a>img {
    height: 120px;
}

.container-login .formulario {
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 25px;
    box-shadow: 2px 2px 13px 5px #66666630;
    max-width: 600px;
    width: 100%;
    margin-top: 30px;
}

.titulo {
    color: #6486B7;
}

.form-floating>.form-control {
    height: calc(3.2rem - 2px);
    font-size: 15px;
}

.form-floating>.form-control-plaintext:-webkit-autofill, .form-floating>.form-control:-webkit-autofill {
    padding-top: 1.545rem;
    padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label {
    opacity: 1;
    color: #939597;
}

.form-floating>label {
    color: #838587;
    font-weight: 400;
    font-size: 14px;
    padding: 0.9rem 0.75rem;
}

.form-floating>.form-control:-webkit-autofill~label {
    transform: scale(.85) translateY(-0.51rem) translateX(0.15rem);
}

.direitos {
    color: #CDCDCD;
    font-size: 14px;
}

.texto-button {
    font-size: 12.8px;
    margin-top: 10px;
    color: #6c6c6c;
    transition: display 0.5s ease-in-out;
}

.texto-button>a {
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}

.limpar-btn {
    text-decoration: none;
    color: #0b5ed7 !important;
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-weight: 700;
}

#esqueci-senha {
    font-size: 13px !important;
    text-align: left;
}

#esqueci-senha>button {
    text-decoration: none;
    background: none;
    border: none;
}

a {
    text-decoration: none;
}