.conteudo-modal {
    display: flex;
    align-items: center;
}

#modal-cookies {
    bottom: 0;
    position: fixed;
    background-color: white;
    box-shadow: 0px -3px 8px 5px rgb(0 0 0 / 20%);
    padding: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    border-radius: 0.5rem 0.5rem 0 0;
}

#modal-cookies .texto {
    max-width: calc(100% - 100px);
    margin-bottom: 0;
    padding-right: 20px;
}

#modal-cookies .btn {
    width: 100px;
}

.btn-limpo-cookie {
    background: transparent;
    color: #0349AE;
    border: none;
}

.btn-limpo-cookie:hover {
    text-decoration: underline;
}