.div-card-imagem {
    height: 180px;
    text-align: center;
    position: relative;
}

.div-card-imagem>.imagem-principal {
    z-index: 1;
    height: auto;
    max-height: 180px;
    width: auto;
    max-width: 100%;
    position: relative;
    top: 90px;
    transform: translateY(-50%);
}

.div-card-imagem>.tag-inferior-direito {
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.badge-card {
    z-index: 2;
    position: absolute;
    font-size: 11px;
    letter-spacing: .02em;
    line-height: 1.1;
}

.badge-mais-vendido.badge-card {
    right: 1.1rem;
}

.card-produto .div-quantidade {
    gap: 8px;
}

.card-produto {
    border: none;
    box-shadow: 0 0 0 1px #D2D2D2;
    padding: 1rem 0.7rem;
    border-radius: 0;
}

.card-produto .card-body {
    padding: 0.6rem 0;
}

.card-produto .nome-produto {
    font-size: 13px;
    height: 60px;
    margin-bottom: 0;
}

.card-produto .div-precos-quantidade {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
}

.card-produto .precos .preco-antigo {
    text-decoration: line-through;
    color: #7b7c7c;
    font-size: 0.8rem;
}

.card-produto .precos .preco {
    font-weight: 600;
}

.card-produto .precos .preco.preco-promocional {
    color: #ff2626;
}

.card-produto .precos .preco-total {
    color: #969696;
    font-size: 0.65rem;
}

.card-produto .div-quantidade>input {
    font-size: 13px;
    width: 55%;
    -moz-appearance: textfield;
    text-align: center;
}

.card-produto .div-quantidade>input::-webkit-inner-spin-button,
.card-produto .div-quantidade>input::-webkit-outer-spin-button {
    -webkit-appearance: none
}

.card-produto .div-quantidade>button {
    font-size: 14px;
    width: 45%;
}

.mensagem-aleitamento {
    font-size: 8px;
    line-height: 1;
    text-align: justify;
}

.badge-favorito {
    right: 20px;
    transition: all ease-in-out 150ms;
    cursor: pointer;
    color: transparent;
}

.badge-favorito.segundo {
    top: 36px;
}

.card-produto:hover .badge-favorito.favorito {
    color: red;
}

.card-produto:hover .badge-favorito:not(.favorito) {
    color: #000000a8
}