#container-meus-dados {
    display: flex;
    padding-top: 15px;
}

#container-ola-usuario {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 30%;
    max-width: 400px;
    max-height: 310px;
}

.titulos {
    color: #444141;
}

.btn-acoes-usuario {
    background-color: transparent;
    border: none;
    text-align: left;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: black;
}

.btn-acoes-usuario.ativo {
    background-color: #8890e328;
    border-right: 4px #0d6efd solid;
}

.btn-acoes-usuario:hover {
    background-color: #8890e328;
    color: black;
}

a.sem-estilo {
    text-decoration: none;
    color: black;
}

.containers {
    border: 1px solid #84848430;
    border-radius: 10px;
    box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 20%);
}

.containers-abas {
    margin-left: 20px;
    width: 70%;
}

@keyframes carregando-bg {
    0% {
        background-position: -400px 0
    }

    100% {
        background-position: 400px 0
    }
}

.carregando {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: carregando-bg;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #CCCCCC 20%, #eeeeee 33%);
    background-size: 100%;
    background-size: 400px 134px;
    position: relative;
    border-radius: 5px;
    color: transparent !important;
    pointer-events: none;
}

.carregando>* {
    visibility: hidden;
}

select {
    height: calc(3.2rem - 2px) !important;
    padding-bottom: 0 !important;
    padding-top: 13px !important;
}

.salvar-cancelar {
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.span-verificado {
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 3px;
    cursor: pointer;
}

.div-email label {
    padding-left: 25px;
}

.span-verificado.enviando {
    pointer-events: none;
    animation: carregando-envio 500ms linear infinite;
}

.span-verificado.ja-verificado {
    cursor: default;
}

@keyframes carregando-envio {
    0% {
        opacity: 0%
    }

    50% {
        opacity: 100%
    }

    100% {
        opacity: 0%
    }
}