.container-verificando {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
}

#span-verificando.verificando {
    animation: efeito-aguarde 1s linear infinite;
}

@keyframes efeito-aguarde {
    0% {
        opacity: 0%
    }

    50% {
        opacity: 100%
    }

    100% {
        opacity: 0%
    }
}