.produtos-promocao {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.div-ordenar {
    display: flex;
    align-items: center;
}

.div-ordenar>select {
    width: unset;
}

.total-ordenacao {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-menu>.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paginacao-produtos {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.btn-pagina {
    width: calc(100% / 11);
}

.produtos-promocao>.card-produto {
    width: 100%;
}

@media (min-width: 576px) {
    .produtos-promocao>.card-produto {
        width: calc(100% / 2);
    }
}

@media (min-width: 768px) {
    .produtos-promocao>.card-produto {
        width: calc(100% / 3);
    }
}

@media (min-width: 992px) {
    .produtos-promocao>.card-produto {
        width: calc(100% / 4);
    }
}

@media (min-width: 1200px) {
    .produtos-promocao>.card-produto {
        width: calc(100% / 5);
    }
}

@media (min-width: 1400px) {
    .produtos-promocao>.card-produto {
        width: calc(100%/ 6);
    }
}

.breadcrumb-item.active::before {
    display: none;
}

.breadcrumb svg {
    font-size: 11px;
}