#categorias-destaque {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#categorias-destaque .categoria {
    cursor: pointer;
    text-align: center;
    color: #404040;
}

#categorias-destaque .categoria .icone {
    color: #353535;
    border-radius: 50%;
    padding: 16px;
    height: 80px;
    width: 80px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1rem;
    margin-bottom: 8px;
    transition-delay: 0.1s;
    transition: all 0.15s;
    margin: auto;
    margin-bottom: 7px;
}

#categorias-destaque .categoria .icone svg {
    fill: #353535;
    width: 100%;
    height: 55px;
}

#categorias-destaque .categoria .icone:hover svg {
    fill: #ffffff;
}

#categorias-destaque .categoria .icone:hover {
    color: white;
    background-color: #353535;
}

#categorias-destaque .categoria label {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.86rem;
    text-transform: uppercase;
}