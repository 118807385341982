.borda-corousel {
    box-shadow: 0 0 0 1px #D2D2D2;
}

.botoes-carousel {
    position: absolute;
    top: calc(50% - 18px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.botoes-carousel.direita {
    right: -30px;
}

.item-anterior-carousel.esquerda {
    left: -30px;
}

.carousel-sessao {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.carousel-sessao .carousel-produtos {
    flex: none;
    max-width: 216px;
}