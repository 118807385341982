.card-endereco {
    min-height: 300px;
    border: 1px solid #0000002b;
    border-radius: 10px;
    padding: 0px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
}

.card-endereco .tag-padrao {
    color: white;
    position: absolute;
    top: 0;
    padding: 2px 8px;
    right: 20px;
    border-radius: 0px 0px 5px 5px;
    font-weight: 500;
    font-size: 12px;
}

.card-endereco>.corpo-card>.descricao {
    font-weight: 700;
}

.card-endereco>.corpo-card>div:not(.descricao) {
    display: flex;
    flex-direction: column;
}

.card-endereco>.botoes {
    display: flex;
    justify-content: space-between;
}

.card-endereco>.corpo-card .text-muted {
    font-size: 12px;
}

/* DIV COM TODOS OS CARDS */
.cards-enderecos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

@media (min-width: 768px) {
    .cards-enderecos {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1200px) {
    .cards-enderecos {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* CARD NOVO ENDEREÇO */
.card-novo-endereco.card-endereco {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.card-novo-endereco.card-endereco:hover #icone-adicionar-end,
.card-novo-endereco.card-endereco:hover span {
    color: rgba(0, 0, 0, 0.6) !important;
    border-color: rgba(0, 0, 0, 0.6);
}

.card-novo-endereco.card-endereco span {
    font-size: 14px;
    color: #00000052;
    transition: all ease-in-out 0.2s;
}

#icone-adicionar-end {
    font-size: 50px;
    border: 1px solid #0000002b;
    padding: 5px 8px;
    border-radius: 50%;
    transition: all ease-in-out 0.2s;
    color: #00000052;
}